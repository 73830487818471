
import { Options, Vue } from 'vue-class-component'
import api from '@/api'
import store from '../../store'
import { toDate } from '../../utils/common'

@Options({
  components: {}
})
export default class ruling extends Vue {
  toDate = toDate;
  table = [];

  pageIndex = 1;
  pageSize = 20;
  pageCount = 0;
  loading = false

  created () {
    this.getTable()
  }

  changePage (val: number) {
    this.pageIndex = val
    this.getTable()
  }

  getTable () {
    this.loading = true
    api
      .getJudgmentDocCheck({
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        ...store.getters['company/get']
      })
      .then((res: any) => {
        this.table = res.data.data
        this.pageCount = res.page.totalRecords
      }).finally(() => {
        this.loading = false
      })
  }

  ckInfo (row: any) {
    this.$router.push({
      path: '/law/ruling/' + row.id
    })
  }
}
